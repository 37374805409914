<template>
  <div class="wrap equipmentWrap">
    <pageHead></pageHead>
    <div class="equipmentCenter wrap">
      <el-image style="width: 100%; height: auto;" :src="require('@/assets/zn.png')" fit="contain"></el-image>
      <div class="equipmentListWrap">
        <div class="equipmentListBox box">
          <div class="equipmentCart"  v-for="(item,index) in equipmentPage" :key="index">
              <div class="equipmentTitle">{{item.name}}</div>
              <div class="equipmentSubtitle">{{item.title}}</div>
              <div class="equipmentInfo">
                <el-image  class="equipmentInfoImage" :src="item.image" fit="contain"></el-image>
                <div class="equipmentInfoCenter">
                  <div class="equipmentInfoCenter_title">{{item.subTitle}}</div>
                  <div class="equipmentInfoCenter_describe" v-html="item.describe"></div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <pageFoot></pageFoot>
  </div>
</template>

<script>
import pageHead from "@/views/assembly/pageHead/pageHead";
import pageFoot from "@/views/assembly/pageFoot/pageFoot";
export default {
  name: "equipment",
  components:{
    pageHead,
    pageFoot
  },
  data(){
    return{

      equipmentPage:this.$store.state.pageInfo.equipmentPage,
      allInfo:this.$store.state.pageInfo.allPage,
    }
  }
}
</script>

<style scoped lang="scss">
.equipmentWrap{
  background: #F7F7F7;
}
.equipmentListWrap{
  width: 100%;
}
.equipmentListBox{
  margin-top: -95px;
  position: relative;
}
.equipmentCart{
  background: #ffffff;
  //padding: 80px 100px 60px;
  padding: 8% 8% 60px;
  margin-top: 40px;
  display:flex;
  flex-direction: column;
  align-items:center;
  .equipmentTitle{
    font-size: 30px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 35px;
  }
  .equipmentSubtitle{
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 12%;
  }
  .equipmentInfo{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .equipmentInfoImage{
      width: 50%;
      height: auto;
    }
    .equipmentInfoCenter{
      //max-width: 360px;
      width: 50%;
      padding-left: 3%;
      padding-top: 20px;
      box-sizing: border-box;
    }
    .equipmentInfoCenter_title{
      font-size: 30px;
      font-weight: 400;
      color: #333333;
      border-bottom: 2px solid #666666;
      padding-bottom: 8%;
    }
    .equipmentInfoCenter_describe{
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
      margin-top: 8%;
      color: #666666;
    }
  }
  &:nth-child(1){
    margin-top: 0;
  }
}
</style>